import { Business, DirectionsCarFilled, People } from "@mui/icons-material";
import { ShoppingCart, Sliders, Users, Cpu, Truck } from "react-feather";

const pagesSection = [
  {
    href: "/",
    icon: Sliders,
    title: "Dashboard",
  },
];

const equipSection = [
  {
    href: "/models",
    icon: Cpu,
    title: "Modelos",
  },
  {
    href: "/equipments",
    icon: Cpu,
    title: "Equipamentos",
  },
  {
    href: "/peripherals",
    icon: Cpu,
    title: "Periféricos",
  },
];

const adminSection = [
  {
    href: "/users",
    icon: Users,
    title: "Usuários",
  },
  {
    href: "/customers",
    icon: Truck,
    title: "Clientes",
  },
  {
    href: "/companies",
    icon: Business,
    title: "Empresas",
  },
  {
    href: "/people",
    icon: People,
    title: "Pessoas",
  },
  {
    href: "/orders",
    icon: ShoppingCart,
    title: "Pedidos",
  },
  {
    href: "/vehicles",
    icon: DirectionsCarFilled,
    title: "Veículos",
  },
];

const masterItems = [
  {
    pages: pagesSection,
  },
  {
    title: "Administrativo",
    pages: adminSection,
  },
  {
    title: "Equipamentos",
    pages: equipSection,
  },
];

export default masterItems;
