import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Companies components
const CompanyList = async(() => import("./pages/companies/List"));
const NewCompany = async(() => import("./pages/companies/New"));
const CompanyDetail = async(() => import("./pages/companies/Detail"));

// Dashboard components
const CustomerList = async(() => import("./pages/customers/List"));
const NewCustomer = async(() => import("./pages/customers/New"));
const CustomerDetail = async(() => import("./pages/customers/Detail"));

// Dashboard components
const Dashboard = async(() => import("./pages/dashboards"));

//Employees components
const PersonList = async(() => import("./pages/people/List"));
const NewPerson = async(() => import("./pages/people/New"));
const PersonDetail = async(() => import("./pages/people/Detail"));

//Equipments components
const EquipmentList = async(() => import("./pages/equipments/List"));
const NewEquipment = async(() => import("./pages/equipments/New"));
const EquipmentDetail = async(() => import("./pages/equipments/Detail"));

//Models components
const ModelList = async(() => import("./pages/models/List"));
const NewModel = async(() => import("./pages/models/New"));
const ModelDetail = async(() => import("./pages/models/Detail"));

//Orders components
const OrderList = async(() => import("./pages/orders/List"));
const NewOrder = async(() => import("./pages/orders/New"));
const OrderDetail = async(() => import("./pages/orders/Detail"));
const TrackOrder = async(() => import("./pages/orders/Track"));

//Models components
const PeripheralList = async(() => import("./pages/peripherals/List"));
const NewPeripheral = async(() => import("./pages/peripherals/New"));
const PeripheralDetail = async(() => import("./pages/peripherals/Detail"));

//Tracks components
const TrackList = async(() => import("./pages/tracks/List"));
const NewTrack = async(() => import("./pages/tracks/New"));
const TrackDetail = async(() => import("./pages/tracks/Detail"));
const TrackTrack = async(() => import("./pages/tracks/Track"));

//Users components
const UserList = async(() => import("./pages/users/List"));
const NewUser = async(() => import("./pages/users/New"));
const UserDetail = async(() => import("./pages/users/Detail"));

//Vehicles components
const VehicleList = async(() => import("./pages/vehicles/List"));
const NewVehicle = async(() => import("./pages/vehicles/New"));
const VehicleDetail = async(() => import("./pages/vehicles/Detail"));
const VehicleTrack = async(() => import("./pages/vehicles/Track"));

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "login",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      // {
      //   path: "",
      //   element: <SignIn />,
      // },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "users",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <UserList />,
      },
      {
        path: "new",
        element: <NewUser />,
      },
      {
        path: "detail/:id",
        element: <UserDetail />,
      },
    ],
  },
  {
    path: "models",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ModelList />,
      },
      {
        path: "new",
        element: <NewModel />,
      },
      {
        path: "detail/:id",
        element: <ModelDetail />,
      },
    ],
  },
  {
    path: "equipments",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <EquipmentList />,
      },
      {
        path: "new",
        element: <NewEquipment />,
      },
      {
        path: "detail/:id",
        element: <EquipmentDetail />,
      },
    ],
  },
  {
    path: "orders",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <OrderList />,
      },
      {
        path: "new",
        element: <NewOrder />,
      },
      {
        path: "detail/:id",
        element: <OrderDetail />,
      },
      {
        path: "track/:id",
        element: <TrackOrder />,
      },
    ],
  },
  {
    path: "customers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CustomerList />,
      },
      {
        path: "new",
        element: <NewCustomer />,
      },
      {
        path: "detail/:id",
        element: <CustomerDetail />,
      },
    ],
  },
  {
    path: "tracks",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TrackList />,
      },
      {
        path: "new",
        element: <NewTrack />,
      },
      {
        path: "detail/:id",
        element: <TrackDetail />,
      },
      {
        path: "track/:id",
        element: <TrackTrack />,
      },
    ],
  },
  {
    path: "peripherals",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PeripheralList />,
      },
      {
        path: "new",
        element: <NewPeripheral />,
      },
      {
        path: "detail/:id",
        element: <PeripheralDetail />,
      },
    ],
  },
  {
    path: "vehicles",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <VehicleList />,
      },
      {
        path: "new",
        element: <NewVehicle />,
      },
      {
        path: "detail/:id",
        element: <VehicleDetail />,
      },
      {
        path: "track/:id",
        element: <VehicleTrack />,
      },
    ],
  },
  {
    path: "companies",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <CompanyList />,
      },
      {
        path: "new",
        element: <NewCompany />,
      },
      {
        path: "detail/:id",
        element: <CompanyDetail />,
      },
    ],
  },
  {
    path: "people",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <PersonList />,
      },
      {
        path: "new",
        element: <NewPerson />,
      },
      {
        path: "detail/:id",
        element: <PersonDetail />,
      },
    ],
  },
];

export default routes;
