import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers(state, payload) {
      state.users = [];
    },
  },
});

export const { reducer } = slice;

export default slice;

// export function getUsers() {
//   return async (dispatch) => {
//     const response = await axios.get("/api/accounts");
//     slice.actions.setUsers(response.data);
//   };
// }
