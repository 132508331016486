import { DirectionsCarFilled } from "@mui/icons-material";
import { ShoppingCart, Sliders } from "react-feather";

const pagesSection = [
  {
    href: "/",
    icon: Sliders,
    title: "Dashboard",
  },
];

const adminSection = [
  {
    href: "/vehicles",
    icon: DirectionsCarFilled,
    title: "Veículos",
  },
];

const operadorItems = [
  {
    pages: pagesSection,
  },
  {
    title: "Administrativo",
    pages: adminSection,
  },
];

export default operadorItems;
